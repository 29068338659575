import React, {Component} from 'react';

class FeelGood extends Component {
    render() {
        return (
            <div id="9" data-id="9" className="swiper-slide">
                <div className={"side_image side_image-9"}/>
                <div className="feelGood-page page">
                    <div className="feelGood__content page__content visible-content">
                        <h2>Fühlen Sie sich wohl</h2>
                        <div className="page_description">
                            <p>
                                FeelGood content
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeelGood;
