import React, { Component } from 'react';
import {Link} from "react-router-dom";

class FeelGood extends Component {

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="feelGood-page page">
                    <div className="feelGood__content page__content visible-content">
                        <h2>Fühlen Sie sich wohl</h2>
                        <div className="page_description">
                            <p>
                                FeelGood content
                            </p>
                        </div>
                        <Link to="/terrasse" className="btn">
                            <div className="front">Terrasse</div>
                            <div className="back">Terrasse</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeelGood;
