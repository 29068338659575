import React, {Component} from 'react';

class Triffles extends Component {
    render() {
        return (
            <div id="4" data-id="4" className="swiper-slide">
                <div className={"side_image side_image-4"}/>
                <div className="trifles-page page">
                    <div className="trifles__content page__content visible-content">
                        <h2>Speisekarte (Kleinigkeiten)
                        </h2>
                        <div className="page_description">
                            <p>Trifles menu</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Triffles;
