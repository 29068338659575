import React, { Component } from 'react';
import {Link} from "react-router-dom";

class Terrasse extends Component {

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="terrasse-page page">
                    <div className="terrasse__content page__content visible-content">
                        <h2>An der Rauschen (Terrasse)</h2>
                        <div className="page_description">
                            <p>
                                Terrasse content
                            </p>
                        </div>
                        <Link to="/team" className="btn">
                            <div className="front">Team</div>
                            <div className="back">Team</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Terrasse;
