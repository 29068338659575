import React, {Component} from 'react';
import arrow from "../../images/back.svg";

class Presentation extends Component {

    componentWillUnmount() {
        if (this.props.isSecondCardOpen) {
            this.props.toggleCard("card2");
        }
    }

    render() {
        return (
            <div id="7" data-id="7"
                 className={`swiper-slide ${this.props.isSecondCardOpen ? "add-index" : null}`}>
                <div className={"side_image side_image-7"}/>
                <div className="presentation-page page">
                    <div
                        className={`presentation__content page__content visible-content ${!this.props.isSecondCardOpen ? "show" : "hide"}`}>
                        <div className="page__content_container">
                            <h2>Vorstellung Konzept</h2>
                            <div className="page_description">
                                <p>
                                    Presentation description
                                </p>
                                <span className="openServices btn" onClick={() => this.props.toggleCard("card2")}>Open presentation</span>
                            </div>
                        </div>
                        <div className={`positions read-more ${this.props.isSecondCardOpen ? "show" : "hide"}`}>
                            <div className="modal__header">
                                <div className="back-btn" onClick={() => this.props.toggleCard("card2")}><img
                                    src={arrow} alt="arrow"/> Zurück
                                </div>
                            </div>
                            <div className="positions__container">
                                <h2>Presentation content</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Presentation;
