import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cross from "../images/cross.svg";

class Home extends Component {

    componentWillUnmount() {
        this.props.closeCard();
    }

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage} ${this.props.isCardOpen ? 'card-opened' : ''}`} />
                <div className="home-page page">
                    <div className="home-page__content page__content visible-content">
                        <h2>Unser Café</h2>
                        <div className="page_description">
                            <p>
                                Ein kleines gemütliches Café, in altem Fachwerk in Bad Münstereifel. Zauberhaft, leckere Speisen zum verwöhnen. Selbstgebackene Kuchen, Torten, herzhafte Leckereien, süße Waffeln, schmackhafte Stullen, Salate oder Crêpes.<br />
                                <span className="openBooking info-btn"
                                    onClick={() => this.props.toggleCard("card1")}>Lesen Sie weiter...</span>
                            </p>
                        </div>
                        <div className={`description read-more ${this.props.cardStates['card1'] ? "show" : "hide"}`}>
                            <div className="description__container">
                                <h2>Unser Café</h2>
                                <div className="description__wrapper d-flex">
                                    <p>
                                        Ein kleines gemütliches Café, in altem Fachwerk in Bad Münstereifel.
                                        Zauberhaft, leckere Speisen zum verwöhnen.
                                        Selbstgebackene Kuchen, Torten, herzhafte Leckereien, süße Waffeln, schmackhafte Stullen, Salate oder Crêpes.
                                        Alles wird von uns mit Herz, feinem Gaumen und der besonderen Portion Liebe hergestellt und verfeinert.
                                    </p>
                                    <p>
                                        Wir möchten, dass sich die ganze Familie wohlfühlt und haben es uns zur Aufgabe gemacht, für jeden etwas dabei zu haben.
                                        „Kleens Café für GROß und klein“ das ist unsere Devise.
                                        Bester Lavazza Kaffee am Kaminfeuer.
                                        Selbst zubereitete Cocktails in Lounge-Atmosphäre am Abend.
                                        Kinderspielecke mit allerhand Spielmöglichkeiten. Und eine Terrasse zum verlieben, mit Blick auf das Rauschen der Erft.
                                    </p>
                                    <p>
                                        Willkommen in der Familie, willkommen im Kleens Café.
                                    </p>
                                </div>
                            </div>
                            <div className="close_button" onClick={() => this.props.closeCard()}>
                                <img src={cross} alt="cross" />
                            </div>
                        </div>
                        <Link to="/events" className="btn">
                            <div className="front">Events</div>
                            <div className="back">Events</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
