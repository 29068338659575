import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Events extends Component {
    // constructor(props) {
    //     super(props);
    //     const today = new Date();
    //
    //     const eventDate = new Date(2023, 10, 17); // Note: Months are zero-based, so 10 represents November
    //     const futureDate = new Date(eventDate);
    //     futureDate.setDate(eventDate.getDate() + 14);
    //
    //     if (today.getTime() > futureDate.getTime()) {
    //         this.state = {eventExpire: true};
    //     } else {
    //         this.state = {eventExpire: false};
    //     }
    // }

    componentWillUnmount() {
        this.props.closeCard();
    }

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div
                    className={`side_image side_image-${this.props.sideImage} ${this.props.cardStates['card2'] ? "side_image-opened-page" : null}`}/>
                <div className="events-page page">
                    <div
                        className={`events__content page__content visible-content ${!this.props.cardStates['card2'] ? "show" : "hide"}`}>
                        <div className="page__content_container">
                            <h2>Events</h2>
                            <div className="page_description">
                                <p>
                                    Zu jeder glücklichen Familie gehören tolle Events genauso dazu, wie das alltägliche
                                    Leben. Um dem Leben im Café Substanz zu geben, werden wir immer wieder verschiedene
                                    Veranstaltungen für euch organisieren. Für jedes Alter wird etwas dabei sein. Wir
                                    freuen uns auf Euch.
                                </p>
                                {/*<div className="events-page-buttons-container">*/}
                                {/*    {!this.state.eventExpire && <span*/}
                                {/*        className="btn-flip"*/}
                                {/*        data-back="17.11.2023 Kunst & Poesie"*/}
                                {/*        data-front="17.11.2023 Kunst & Poesie"*/}
                                {/*        onClick={() => this.props.toggleCard("card-button4")}/>}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        {/*<div*/}
                        {/*    className={`positions read-more ${this.props.cardStates['card-button4'] ? "show" : "hide"}`}>*/}
                        {/*    <Card4/>*/}
                        {/*    <div className="close_button" onClick={() => this.props.closeCard()}>*/}
                        {/*        <img src={cross} alt="cross"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <Link to="/fruhstuck" className="btn">
                            <div className="front">Frühstück</div>
                            <div className="back">Frühstück</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Events;
