import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cross from "../images/cross.svg";

class Presentation extends Component {

    componentWillUnmount() {
        if (this.props.isSecondCardOpen) {
            this.props.toggleCard("card2");
        }
    }

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div
                    className={`side_image side_image-${this.props.sideImage} ${this.props.isSecondCardOpen ? "side_image-opened-page" : null}`}/>
                <div className="presentation-page page">
                    <div
                        className={`presentation__content page__content visible-content ${!this.props.isSecondCardOpen ? "show" : "hide"}`}>
                        <div className="page__content_container">
                            <h2>Vorstellung Konzept</h2>
                            <div className="page_description">
                                <p>
                                   Presentation description
                                </p>
                                <span className="openServices info-btn" onClick={() => this.props.toggleCard("card2")}>Open presentation</span>
                            </div>
                            <Link to="/kinderspielplatz" className="btn">
                                <div className="front">Kinderspielplatz</div>
                                <div className="back">Kinderspielplatz</div>
                            </Link>
                        </div>
                        <div className={`positions read-more ${this.props.isSecondCardOpen ? "show" : "hide"}`}>
                            Presentation content
                            <div className="close_button" onClick={() => this.props.toggleCard("card2")}>
                                <img src={cross} alt="cross"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Presentation;
