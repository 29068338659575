import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class CafeMenu extends Component {

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="menu-page page">
                    <div className="menu-page__content page__content visible-content">
                        <h2>Speisekarte (Café)</h2>
                        <div className="page_description">
                            <p>
                                Main menu
                            </p>
                        </div>
                        <Link to="/getrankekarte" className="btn">
                            <div className="front">Getränkekarte</div>
                            <div className="back">Getränkekarte</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default CafeMenu;
