import React, {Component} from 'react';
import {Navbar, Container} from 'react-bootstrap';
import logo from '../images/top_logo.svg'
import {NavHashLink as NavLink} from "react-router-hash-link";
import {BrowserView, MobileView} from "react-device-detect";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {menuOpened: false};
    }

    closeMenu() {
        this.setState({menuOpened: false})
        document.getElementById(1).scrollIntoView({
            behavior: 'smooth'
        });
    }

    handleMenu(path) {
        this.setState({menuOpened: !this.state.menuOpened});
        document.getElementById(path).scrollIntoView({
            behavior: 'smooth'
        });
    }

    render() {

        return (
            <Navbar collapseOnSelect expand="xxxl" id="navigaton" variant="dark">
                <Container fluid>
                    <div className="empty"/>
                    <span onClick={() => this.closeMenu()} className={`logo ${!this.state.menuOpened ? "nav-logo" : ""}`}>
                        <img
                            src={logo}
                            width="80"
                            height="80"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </span>
                    <button type="button" className={`navbar-toggler ${!this.state.menuOpened ? "collapsed" : ""}`}
                            onClick={() => this.handleMenu()}>
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <Navbar.Collapse id="basic-navbar-nav" className={`${this.state.menuOpened ? "show" : ""}`}>
                        <BrowserView className="d-flex navbar__wrapper">
                            <Container className={"justify-content-start"}>
                                <NavLink to="/#top" className="nav-link home" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/01</span>Unser Café</NavLink>
                                <NavLink to="/events#2" className="nav-link team" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/02</span>Events</NavLink>
                                <NavLink to="/fruhstuck#3" className="nav-link fruhstuck" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/03</span>Frühstück</NavLink>
                                <NavLink to="/kleinigkeiten#4" className="nav-link kleinigkeiten" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/04</span>Kleinigkeiten</NavLink>
                                <NavLink to="/menu#5" className="nav-link menu" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/05</span>Café</NavLink>
                                <NavLink to="/getrankekarte#6" className="nav-link getrankekarte" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/06</span>Getränkekarte</NavLink>
                            </Container>
                            <Container className={"justify-content-start"}>
                                <NavLink to="/vorstellung#7" className="nav-link vorstellung" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/07</span>Vorstellung Konzept</NavLink>
                                <NavLink to="/kinderspielplatz#8" className="nav-link kinderspielplatz" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/08</span>Kinderspielplatz</NavLink>
                                <NavLink to="/fuhlen#9" className="nav-link fuhlen" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/09</span>Fühlen Sie sich wohl</NavLink>
                                <NavLink to="/terrasse#10" className="nav-link terrasse" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/10</span>Terrasse</NavLink>
                                <NavLink to="/team#11" className="nav-link events" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/11</span>Team</NavLink>
                            </Container>
                            <div className="header-footer">
                                <span className="modalButton"
                                      onClick={this.props.openLegalMentions}>Impressum</span>
                            </div>
                        </BrowserView>
                        <MobileView className="navbar-mobile container navbar__wrapper">
                            <span className="nav-link home"
                                     onClick={() => this.handleMenu(1)}><span>/01</span>Unser Café</span>
                            <span className="nav-link events"
                                     onClick={() => this.handleMenu(2)}><span>/02</span>Events</span>
                            <span className="nav-link fruhstuck"
                                     onClick={() => this.handleMenu(3)}><span>/03</span>Frühstück</span>
                            <span className="nav-link kleinigkeiten"
                                     onClick={() => this.handleMenu(4)}><span>/04</span>Kleinigkeiten</span>
                            <span className="nav-link menu"
                                     onClick={() => this.handleMenu(5)}><span>/05</span>Café</span>
                            <span className="nav-link getrankekarte"
                                     onClick={() => this.handleMenu(6)}><span>/06</span>Getränkekarte</span>
                            <span className="nav-link vorstellung"
                                     onClick={() => this.handleMenu(7)}><span>/07</span>Vorstellung Konzept</span>
                            <span className="nav-link kinderspielplatz"
                                     onClick={() => this.handleMenu(8)}><span>/08</span>Kinderspielplatz</span>
                            <span className="nav-link fuhlen"
                                     onClick={() => this.handleMenu(9)}><span>/09</span>Fühlen Sie sich wohl</span>
                            <span className="nav-link terrasse"
                                     onClick={() => this.handleMenu(10)}><span>/10</span>Terrasse</span>
                            <span className="nav-link team"
                                     onClick={() => this.handleMenu(11)}><span>/11</span>Team</span>
                            <div className="header-footer">
                                <span className="modalButton"
                                      onClick={this.props.openLegalMentions}>Legal Mentions</span>
                            </div>
                        </MobileView>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default Header;
