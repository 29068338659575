import React, {Component} from 'react';

class CafeMenu extends Component {
    render() {
        return (
            <div id="5" data-id="5" className="swiper-slide">
                <div className={"side_image side_image-5"}/>
                <div className="menu-page page">
                    <div className="menu-page__content page__content visible-content">
                        <h2>Speisekarte (Café)</h2>
                        <div className="page_description">
                            <p>
                                Main menu
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CafeMenu;
