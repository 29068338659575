import React, { useState, useEffect } from "react";
import { useNavigate, Route, Link, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import Team from "./Pages/Team";
import Breakfast from "./Pages/Breakfast";
import Trifles from "./Pages/Trifles";
import CafeMenu from "./Pages/CafeMenu";
import Drinks from "./Pages/Drinks";
import Presentation from "./Pages/Presentation";
import Playground from "./Pages/Playground";
import FeelGood from "./Pages/FeelGood";
import Terrasse from "./Pages/Terrasse";
import Events from "./Pages/Events";
import SlideRoutes from 'react-slide-routes';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { isMobile } from "react-device-detect";
import MobileHome from "./Components/MobileView/Home";
import MobileTeam from "./Components/MobileView/Team";
import MobileBreakfast from "./Components/MobileView/Breakfast";
import MobileTrifles from "./Components/MobileView/Triffles";
import MobileCafeMenu from "./Components/MobileView/CafeMenu";
import MobileDrinks from "./Components/MobileView/Drinks";
import MobilePresentation from "./Components/MobileView/Presentation";
import MobilePlayground from "./Components/MobileView/Playground";
import MobileFeelGood from "./Components/MobileView/FeelGood";
import MobileTerrasse from "./Components/MobileView/Terrasse";
import MobileEvents from "./Components/MobileView/Events";

function AppRouter() {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    let navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(1);
    const [cardStates, setCardStates] = useState({});
    const [isCardOpen, setIsCardOpen] = useState(false);

    // Function to toggle the card's open/close state
    function toggleCard(cardKey) {
      setCardStates((prevState) => ({
        ...prevState,
        [cardKey]: !prevState[cardKey],
      }));
      setIsCardOpen(true);
    }

    function closeCard() {
        setCardStates({})
        setIsCardOpen(false);
      }

    useEffect(() => {
        setActiveStep(Number(document.getElementsByClassName('swiper-slide')[0].getAttribute('data-id')));
    },[navigate]);

    const routes = {
        1: "/",
        2: "/events",
        3: "/fruhstuck",
        4: "/kleinigkeiten",
        5: "/menu",
        6: "/getrankekarte",
        7: "/vorstellung",
        8: "/kinderspielplatz",
        9: "/fuhlen",
        10: "/terrasse",
        11: "/team"
    }

    function goToSlide(goToId) {
        if (goToId >= 1 && goToId <= 11) {
            navigate(routes[goToId]);
        }
    }

    function goToNextStep() {
        let nextSlideId = Number(document.getElementsByClassName('swiper-slide')[0].getAttribute('data-id')) + 1;
        goToSlide(nextSlideId);
    }

    function goToPrevStep() {
        let nextSlideId = Number(document.getElementsByClassName('swiper-slide')[0].getAttribute('data-id')) - 1;
        goToSlide(nextSlideId);
    }

    if (isMobile) {
        return (
            <>
                <MobileHome
                    toggleCard = {toggleCard}
                    cardStates={cardStates}
                    closeCard={closeCard}
                    isCardOpen={isCardOpen}
                />
                <MobileEvents
                    toggleCard={toggleCard}
                    cardStates={cardStates}
                    closeCard={closeCard}
                    isCardOpen={isCardOpen}
                />
                <MobileBreakfast/>
                <MobileTrifles/>
                <MobileCafeMenu/>
                <MobileDrinks/>
                <MobilePresentation/>
                <MobilePlayground/>
                <MobileFeelGood/>
                <MobileTerrasse/>
                <MobileTeam/>
            </>
        )
    }

    return (
        <ReactScrollWheelHandler
            upHandler={goToPrevStep}
            downHandler={goToNextStep}
            pauseListeners={isCardOpen}
        >
            <div className={`stepper ${isCardOpen ? 'hide' : 'show'}`}>
                {Object.entries(routes).map((route) => (
                    <Link
                        key={route[0]}
                        to={route[1]}
                        className={activeStep === Number(route[0]) ? 'active' : ''}
                    />
                ))}
            </div>
            <SlideRoutes
                animation="vertical-slide"
                timing="ease-in-out"
                duration={500}
            >
                <Route key={1} path="/" element={
                    <Home
                        sideImage={1}
                        toggleCard = {toggleCard}
                        cardStates={cardStates}
                        closeCard={closeCard}
                        isCardOpen={isCardOpen}
                    />}/>
                <Route key={2} path="/events" element={
                    <Events
                        sideImage={2}
                        toggleCard={toggleCard}
                        cardStates={cardStates}
                        closeCard={closeCard}
                    />}/>
                <Route key={3} path="/fruhstuck" element={<Breakfast sideImage={3}/>}/>
                <Route key={4} path="/kleinigkeiten" element={<Trifles sideImage={4}/>}/>
                <Route key={5} path="/menu" element={<CafeMenu sideImage={5}/>}/>
                <Route key={6} path="/getrankekarte" element={<Drinks sideImage={6}/>}/>
                <Route key={7} path="/vorstellung" element={<Presentation sideImage={7}/>}/>
                <Route key={8} path="/kinderspielplatz" element={<Playground sideImage={8}/>}/>
                <Route key={9} path="/fuhlen" element={<FeelGood sideImage={9}/>}/>
                <Route key={10} path="/terrasse" element={<Terrasse sideImage={10}/>}/>
                <Route key={11} path="/team" element={<Team sideImage={11}/>}/>
            </SlideRoutes>
        </ReactScrollWheelHandler>
    )
}

export default AppRouter;
