import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Drinks extends Component {

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="drinks-page page">
                    <div className="drinks__content page__content visible-content">
                        <h2>Getränkekarte</h2>
                        <div className="page_description">
                            <p>
                                Drinks menu
                            </p>
                        </div>
                        <Link to="/vorstellung" className="btn">
                            <div className="front">Vorstellung Konzept</div>
                            <div className="back">Vorstellung Konzept</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Drinks;
