import React from "react";

const Card4 = () => {
    return (
        <div className="description__container">
            <h2>Kunst & Poesie</h2>
            <div className="description__wrapper d-flex">
                <div className="event-info">
                    <p className="date">Am 17.11.2023</p>
                    <p>startet der 2. Termin unserer ersten Veranstaltungsreihe getreu dem Motto <strong>„Kunst &
                        Poesie“. </strong></p>
                    <p>Öffnen werden wir an den Abenden jeweils um <strong>18:00 Uhr</strong>, sodass wir mit unserem
                        leckeren Menü starten können. Um ca. <strong>19:30 Uhr</strong> werden unsere Künstler mit ihren
                        Darbietungen beginnen.</p>
                    <p>Wir begrüßen herzlich unsere Mitorganisatorin <strong>LouCiva</strong></p>
                    <p>Liebe(s)Leben von und mit LouCiva.</p>
                    <p>Lasst Euch von Lou in die Welten der Liebe und der Erotik verführen – natürlich aus weiblicher
                        Sicht. Da werden die „erogenen“ Zonen der Männer ebenso aufs Korn genommen wie Blind Dates oder
                        ihre Sicht auf die Käuflichkeit der Liebe. Aber auch wunderschöne Liebeslieder dürfen nicht
                        fehlen: Lou spannt in ihren Chansons den Bogen über das Kennenlernen, Zusammensein, Trennungen –
                        und was kommt dann? Ist die Liebe fürs Leben? Was können wir tun, damit in einer Beziehung die
                        Liebe lebendig bleibt? Lou erzählt von Mitteln, die Jahrhunderte alte Tradition haben wie
                        aphrodisische Speisen. Sie stellt uns einige vor und lässt uns auch daran „knabbern….“</p>
                    <p>Infos und Musik unter <a href="http://www.louciva.de" className="link-underline">www.louciva.de</a></p>
                </div>
                <div className="menu">
                    <h3>3-Gang-Menu</h3>
                    <div className="menu-item">
                        <p><strong>Vorspeise:</strong> Bruschetta di Pomodore und Bruschetta mit Steinpilz-Kürbiskernpesto</p>
                        <p><strong>Hauptgang:</strong> Linguine mit Avocado - Basilikum-Pesto, dazu Lachs mit einem Hauch von Chili</p>
                        <p><strong>Dessert:</strong> Champagner- und Vanille-Mousse mit frischen Früchten, auf Schokolade und Zimt gebettet</p>
                        <p className="price">32,80 €</p>
                    </div>
                </div>
                <div className="menu">
                    <h3>Häppchenplatte</h3>
                    <div className="menu-item">
                        <p>Canapés-Platte aus sechs verschiedenen belegten Canapé:</p>
                        <ul>
                            <li>- Feldsalat, Forelle und Granatapfel</li>
                            <li>- Gratinierter Ziegenkäse mit Feige und Honig</li>
                            <li>- Schwarzwälder Schinken mit Cornichons</li>
                            <li>- Lachs und Kräuterschmand</li>
                            <li>- Tomate-Mozzarella</li>
                            <li>- Bruschetta</li>
                        </ul>
                        <p className="price">15,60 €</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Card4;
