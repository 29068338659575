import React, {Component} from 'react';

class Playground extends Component {
    render() {
        return (
            <div id="8" data-id="8" className="swiper-slide">
                <div className={"side_image side_image-8"}/>
                <div className="playground-page page">
                    <div className="playground__content page__content visible-content">
                        <h2>Besonderheit Kinderspielplatz</h2>
                        <div className="page_description">
                            <p>
                                Playground content
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Playground;
