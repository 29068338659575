import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Breakfast extends Component {

    render() {

        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="breakfast-page page">
                    <div className="breakfast__content page__content visible-content">
                        <h2>Speisekarte (Frühstück)</h2>
                        <div className="page_description">
                            <p>Breakfast menu</p>
                        </div>
                        <Link to="/kleinigkeiten" className="btn">
                            <div className="front">Kleinigkeiten</div>
                            <div className="back">Kleinigkeiten</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Breakfast;
