import React, {Component} from 'react';
import Card4 from "../CardsHtml/Card4";
import arrow from "../../images/back.svg";

class Events extends Component {
    render() {
        return (
            <div id="2" data-id="2" className={`swiper-slide ${this.props.isCardOpen ? "add-index" : null}`}>
                <div className={`side_image side_image-2 ${this.props.isCardOpen ? 'hide' : null}`}/>
                <div className="events-page page">
                    <div
                        className={`events__content page__content visible-content ${!this.props.isCardOpen ? "show" : "hide"}`}>
                        <div className="page__content_container">
                            <h2>Events</h2>
                            <div className="page_description">
                                <p>
                                    Zu jeder glücklichen Familie gehören tolle Events genauso dazu, wie das alltägliche
                                    Leben. Um dem Leben im Café Substanz zu geben, werden wir immer wieder verschiedene
                                    Veranstaltungen für euch organisieren. Für jedes Alter wird etwas dabei sein. Wir
                                    freuen uns auf Euch.
                                </p>
                                <div className="events-page-buttons-container">
                                    <span
                                        className="btn-flip"
                                        data-back="17.11.2023 Kunst & Poesie"
                                        data-front="17.11.2023 Kunst & Poesie"
                                        onClick={() => this.props.toggleCard("card-button4")}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`positions description read-more ${this.props.cardStates['card-button4'] ? "show" : "hide"}`}>
                            <div className="modal__header">
                                <div className="back-btn" onClick={() => this.props.closeCard()}><img
                                    src={arrow} alt="arrow"/> Zurück
                                </div>
                            </div>
                            <Card4/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Events;
