import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import menuOpened from './Header'
import Modal from "react-bootstrap/Modal";
import logo from "../images/top_logo.svg";
import arrow from "../images/back.svg";

class Footer extends Component {

    render() {

        const currentYear = new Date().getFullYear();

        return (
            <>
                <footer className={`footer showing ${!menuOpened ? "header__footer" : ""}`}>
                    <Container fluid>
                        <span className="modalButton mobile-anchor" onClick={this.props.openLegalMentions}>Impressum</span>
                        <a href="/agb.pdf" target="_blank" rel="noopener noreferrer" className="link mobile-anchor">AGB</a>
                        <span className="legal-text mobile-anchor">Kleen's Cafe {currentYear}. | All rights reserved.</span>
                    </Container>
                </footer>
                <Modal
                    show={this.props.showLegalMentions}
                    onHide={this.props.openLegalMentions}
                    fullscreen={true}
                    animation={true}
                    centered
                    scrollable
                >
                    <Modal.Header className="modal-desktop">
                        <img
                            src={logo}
                            width="80"
                            height="80"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                        <button type="button" className="button-close" onClick={this.props.openLegalMentions}
                                aria-label="Close">
                            <span className="button-close-icon"/>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-mobile">
                            <div className="modal__header">
                                <div className="back-btn" onClick={this.props.openLegalMentions} aria-label="Close"><img
                                    src={arrow} alt="arrow"/> Zurück
                                </div>
                            </div>
                        </div>
                        <h2 className="title-72">Impressum Kleens Café</h2>
                        <p>
                            Angaben gemäß § 5 TMG:<br/>
                            Kleens Café<br/>
                            Heisterbacherstr. 1<br/>
                            53902 Bad Münstereifel
                        </p>
                        <p>
                            Kontakt:<br/>
                            Tel.: <a href="tel:+49022535455700">+49 (0) 2253 5455700</a><br/>
                            E-Mail: <a href="mailto:kleens.cafe@gmail.com">kleens.cafe@gmail.com</a><br/>
                            <a href="/">www.kleens-cafe.de</a>
                        </p>
                        <p>
                            Geschäftsinhaber: Denys Kleen<br/>
                            Steuernummer: 20950685345
                        </p>
                        <p>
                            Plattform der EU-Kommission zur Online-Streitbeilegung:<br/>
                            <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a><br/>
                            Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default Footer;
