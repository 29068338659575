import React, { Component } from 'react';

class Team extends Component {
    render() {
        return (
            <div id="11" data-id="11" className="swiper-slide">
                <div className={"side_image side_image-11"}/>
                <div className="team-page page">
                    <div className="team__content page__content visible-content">
                        <h2>Team</h2>
                        <div className="page_description">
                            <p>Team</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Team;
