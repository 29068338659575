import React, {Component} from 'react';
import arrow from "../../images/back.svg";

class Home extends Component {

    componentWillUnmount() {
        if (this.props.isFirstCardOpen) {
            this.props.toggleCard("card1");
        }
    }

    render() {
        return (
            <div id="1" data-id="1" className={`swiper-slide ${this.props.cardStates['card1'] ? "add-index" : null}`}>
                <div className={`side_image side_image-1`}/>
                <div className="home-page page">
                    <div
                        className={`home-page__content page__content visible-content ${!this.props.cardStates['card1'] ? "show" : "hide"}`}>
                        <h2>Unser Café</h2>
                        <div className="page_description">
                            <p>
                                Ein kleines gemütliches Café, in altem Fachwerk in Bad Münstereifel. Zauberhaft, leckere Speisen zum verwöhnen. Selbstgebackene Kuchen, Torten, herzhafte Leckereien, süße Waffeln, schmackhafte Stullen, Salate oder Crêpes.<br />
                                <span className="openBooking info-btn"
                                      onClick={() => this.props.toggleCard("card1")}>Lesen Sie weiter...</span>
                            </p>
                        </div>
                        <div className={`description read-more ${this.props.cardStates['card1'] ? "show" : "hide"}`}>
                            <div className="modal__header">
                                <div className="back-btn" onClick={() => this.props.closeCard()}><img
                                    src={arrow} alt="arrow"/> Zurück
                                </div>
                            </div>
                            <div className="description__container">
                                <h2>Unser Café</h2>
                                <div className="description__wrapper d-flex unser">
                                    <p>
                                        Ein kleines gemütliches Café, in altem Fachwerk in Bad Münstereifel.
                                        Zauberhaft, leckere Speisen zum verwöhnen.
                                        Selbstgebackene Kuchen, Torten, herzhafte Leckereien, süße Waffeln, schmackhafte Stullen, Salate oder Crêpes.
                                        Alles wird von uns mit Herz, feinem Gaumen und der besonderen Portion Liebe hergestellt und verfeinert.
                                    </p>
                                    <p>
                                        Wir möchten, dass sich die ganze Familie wohlfühlt und haben es uns zur Aufgabe gemacht, für jeden etwas dabei zu haben.
                                        „Kleens Café für GROß und klein“ das ist unsere Devise.
                                        Bester Lavazza Kaffee am Kaminfeuer.
                                        Selbst zubereitete Cocktails in Lounge-Atmosphäre am Abend.
                                        Kinderspielecke mit allerhand Spielmöglichkeiten. Und eine Terrasse zum verlieben, mit Blick auf das Rauschen der Erft.
                                    </p>
                                    <p>
                                        Willkommen in der Familie, willkommen im Kleens Café.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
