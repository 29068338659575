import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './style/index.scss';
import App from './App';
import HttpsRedirect from 'react-https-redirect';

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <HttpsRedirect>
                <App/>
            </HttpsRedirect>
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById('root')
);
