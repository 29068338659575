import React, {Component} from 'react';

class Terrasse extends Component {
    render() {
        return (
            <div id="10" data-id="10" className="swiper-slide">
                <div className={"side_image side_image-10"}/>
                <div className="terrasse-page page">
                    <div className="terrasse__content page__content visible-content">
                        <h2>An der Rauschen (Terrasse)</h2>
                        <div className="page_description">
                            <p>
                                Terrasse content
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Terrasse;
