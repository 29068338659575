import React, {Component} from 'react';

class Drinks extends Component {
    render() {
        return (
            <div id="6" data-id="6" className="swiper-slide">
                <div className={"side_image side_image-6"}/>
                <div className="drinks-page page">
                    <div className="drinks__content page__content visible-content">
                        <h2>Getränkekarte</h2>
                        <div className="page_description">
                            <p>
                                Drinks menu
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Drinks;
